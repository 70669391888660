import { MenuSidebarView } from '@components/Common/UserNav';
import ProfilePortalSidebar from '@components/Profile/Portal/ProfilePortal';
import ServicePortalSidebarView from '@components/Profile/ServicePortalSidebar';
import StoreFinderSidebarView from '@components/Stores/StoreFinderSidebarView';
import Sidebar from '@components/ui/Sidebar';
import { useUI } from 'components/ui/context';

const SidebarView = ({ sidebarView, closeSidebar, menuItems }) => {
  let slideInFrom = 'slideInFromRight';
  let classNames = 'w-full';
  if (sidebarView === 'MOBILE_MENU_VIEW') {
    slideInFrom = 'slideInFromLeft';
    classNames = 'w-full mobileMenuCls';
  }

  if (sidebarView === 'SERVICE_PORTAL_VIEW') classNames = 'w-full md:w-92';
  if (sidebarView === 'USER_PORTAL_VIEW') classNames = 'w-full md:w-92';

  if (sidebarView === 'STORE_FINDER_VIEW') {
    classNames = 'w-full md:w-92';
    slideInFrom = 'slideInFromLeft';
  }

  if (sidebarView === 'PRODUCT_FILTER_VIEW') {
    return null;
  }

  return (
    <Sidebar onClose={closeSidebar} slideInFromClassName={slideInFrom} classNames={classNames}>
      {sidebarView === 'MOBILE_MENU_VIEW' && <MenuSidebarView menuItems={menuItems} />}
      {sidebarView === 'SERVICE_PORTAL_VIEW' && <ServicePortalSidebarView onClose={closeSidebar} />}
      {sidebarView === 'STORE_FINDER_VIEW' && <StoreFinderSidebarView onClose={closeSidebar} />}
      {sidebarView === 'USER_PORTAL_VIEW' && <ProfilePortalSidebar menuItems={menuItems} />}
    </Sidebar>
  );
};

const SidebarUI = ({ menuItems, closeSidebar }) => {
  const { displaySidebar, sidebarView } = useUI();

  return displaySidebar ? (
    <SidebarView menuItems={menuItems} sidebarView={sidebarView} closeSidebar={closeSidebar} />
  ) : null;
};

export default SidebarUI;
