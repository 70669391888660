export default {
  currencies: [
    {
      currencyCode: 'AUD',
      currencySymbol: '$',
      currencyIconUrl: '/icons/dollar.svg',
      contexts: ['AU'],
    },
  ],
  contexts: [
    {
      country: 'Australia',
      code: 'AU',
      title: 'AU',
      routePrefix: '/',
      language: 'en-AU',
      storefrontUrl: 'https://rejectshop.com.au',
      shopifyHandle: 'therejectshop',
      shopifyStorefrontToken: '49911a9ce0c164fb103d497efe652eac',
      storefrontGqlUrl: 'https://checkout.rejectshop.com.au/api/graphql',
      defaultCurrencyCode: 'AUD',
      default: true,
    },
  ],
  brand: {
    prismicApiEndpoint: 'https://trs-prismic-content-api.b-cdn.net/api/v2',
    prismicAccessToken:
      'MC5ZejBSZnhFQUFDRUFnTjg5.Mu-_ve-_vUzvv70pKxsL77-9XO-_vUdvdlcTGu-_vVIwee-_ve-_vUlt77-977-9M--_vWEq',
    regenerateTimeout: 3600,
    googleApiKey: 'AIzaSyBJueo_0M63sCgif_bTUV7ueAQOIKISQ60',
  },
  domains: {
    production: ['rejectshop.com.au'],
    staging: ['rejectshop.now.sh'],
  },
  zendeskKey: '733c7a50-1509-4fa8-8caf-578c0f48dc3d',
};
