import { useUI } from '@components/ui';
import { LocalisationConnectorContext } from '@containers/LocalisationConnector/LocalisationConnectorContext';
import useStoreFinderStorage from '@lib/hooks/useStoreFinderStorage';
import Link from 'next/link';
import { EventContext } from 'providers/EventProvider';
import { memo, useContext, useMemo, useState } from 'react';
import breakPoints, { brandPrimaryDarker } from 'utils/styleConfig';
import { StoreConfirmTip } from '../StoreConfirmTip';
import { permanentlyClosedStores } from '@lib/permanently-closed-stores';

const Ribbon = () => {
  const { openSidebar, setSidebarView, displaySidebar, displayModal } = useUI();

  let isStorePermanentlyClosed;

  const [_myStore] = useStoreFinderStorage();
  const [myStore, setMyStore] = useState(_myStore);
  const { on } = useContext(LocalisationConnectorContext);
  const { gtm } = useContext(EventContext);

  on('store_finder_page_set_store', (store) => {
    setMyStore(store);
  });
  on('store_finder_sidebar_set_store', (store) => {
    setMyStore(store);
  });

  if (permanentlyClosedStores.indexOf(myStore?.id) !== -1) {
    isStorePermanentlyClosed = true;
  }

  const storeName = myStore?.name;
  const storeId = myStore?.id;
  const openingFrom = useMemo(() => {
    const today = new Date().toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    const openingHours = myStore?.open_hours?.find((o) => o?.day === today);
    return openingHours?.time ?? null;
  }, [myStore]);

  let postcode = null;
  if (typeof myStore?.address === 'string') {
    postcode = myStore?.address?.split(',')?.pop().replace(' ', '');
  } else if (typeof myStore?.address === 'object') {
    postcode = myStore?.address?.postcode;
  }

  if (typeof window !== 'undefined') {
    window.dispatchEvent(
      new CustomEvent('SavedData', {
        detail: {
          savedPostcode: postcode,
          savedStore: storeName,
          savedStoreId: storeId,
        },
      })
    );
  }

  const openStoreSidebar = () => {
    setSidebarView('STORE_FINDER_VIEW');
    openSidebar();
    gtm.tagClickChangeStore();
  };

  return (
    <>
      <div className="ribbon-container">
        <div className="header-ribbon wrapper-large">
          <div className="delivery-location">
            <div className="saved-search">
              <div className="flex flex-row lg:flex-row gap-0 lg:gap-2">
                <div className="relative">
                  {!storeName || isStorePermanentlyClosed ? (
                    <button
                      className="relative bg-transparent text-white cursor-pointer underline store-details"
                      type="button"
                      onClick={openStoreSidebar}
                    >
                      <span>Set your store</span>
                    </button>
                  ) : (
                    <button
                      className="relative flex flex-wrap items-center bg-transparent border-none text-white store-details"
                      onClick={openStoreSidebar}
                    >
                      <span className="inline-block">Your store&nbsp;</span>
                      <span className="inline-block store-name store-details underline">
                        {storeName}
                      </span>
                    </button>
                  )}
                  {!displaySidebar && !displayModal && <StoreConfirmTip />}
                </div>

                {openingFrom && !isStorePermanentlyClosed && (
                  <div className="flex flex-row flex-wrap">
                    <span>Open today:&nbsp;</span>
                    <span className="uppercase">{openingFrom}</span>
                  </div>
                )}
                <span className="divider">|</span>
                <Link href="/stores" className="store-details">
                  <span className="store-details flex" onClick={() => gtm.tagClickStoreFinder()}>
                    Store Finder
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="other-information">
            <Link href="/doordash" as="/doordash">
              Shop Via DoorDash
            </Link>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .ribbon-container {
            background-color: ${brandPrimaryDarker};
            // overflow: auto;
          }
          .delivery-location {
            display: flex;
          }
          .postcode-state {
            display: flex;
          }
          .locate-icon {
            margin-right: 10px;
          }
          .postcode {
            cursor: pointer;
            padding: 0 3px;
            text-decoration: underline;
          }
          .divider {
            padding: 0 3px;
          }
          .saved-search {
            display: flex;
          }
          .saved-search div:nth-child(1),
          .saved-search div:nth-child(2) {
            display: flex;
          }
          .saved-search .location,
          .store-name {
            color: #ffed00;
            cursor: pointer;
            padding-right: 8px;
          }
          .location {
            margin-left: 5px;
          }
          .saved-search .location:hover {
            text-decoration: underline;
          }
          .store-name:hover {
            text-decoration: underline;
          }
          .mobile {
            display: none;
          }
          .delivery-to-status {
            padding-left: 10px;
          }
          .store-hours {
            display: inline;
          }
          .store-hours-time {
            text-transform: uppercase;
          }
          .enter-address-text {
            padding-left: 8px;
          }
          .header-ribbon {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
          }
          .other-information > *,
          .header-ribbon > * {
            margin: 0 8px;
          }
          .delivery-location:first-child {
            margin-left: 0;
          }
          .other-information:last-child {
            margin-right: 0;
          }
          .store-details {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
          }
          @media (max-width: ${breakPoints.small}) {
            .mobile {
              display: block;
            }
            .desktop {
              display: none;
            }
            .store-hours {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default memo(Ribbon);
