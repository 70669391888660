import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import * as Sentry from '@sentry/nextjs';
import { FilledLinkToDocumentField, PrismicDocument } from '@prismicio/types';
import sm from '../sm.json';

const getRepositoryName = (repositoryEndpoint: string): string => {
  try {
    const hostname = new URL(repositoryEndpoint).hostname;

    if (
      hostname.endsWith('prismic.io') || // Production
      hostname.endsWith('wroom.io') || // Staging
      hostname.endsWith('wroom.test') || // Dev
      hostname.endsWith('b-cdn.net') // Proxy through Bunny
    ) {
      return hostname.split('.')[0];
    }
  } catch {}

  throw new Error(`An invalid Prismic Document API endpoint was provided: ${repositoryEndpoint}`);
};

export const repositoryName = getRepositoryName(sm.apiEndpoint);

export function linkResolver(doc: FilledLinkToDocumentField) {
  if (!doc || !doc?.uid || doc.uid === 'homepage') return '/';

  switch (doc.type) {
    case 'category_landing_page':
      return `/c/l/${doc.uid.replace('clp-', '')}`;
    case 'event_landing_page':
      return `/e/l/${doc.uid.replace('elp-', '')}`;
    case 'brands_page':
      return `/brands`;
    case 'doordash_page':
      return `/doordash`;
    case 'careers':
      return `/careers/${doc.uid.replace('careers-', '')}`;
    case 'main_navigation':
      return `/`;
    case 'footer_navigation':
      return `/`;
    default:
      return '/';
  }
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export function createClient(config: any = {}) {
  const client = prismic.createClient(sm.apiEndpoint, {
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config?.previewData,
    req: config?.req,
  });

  return client;
}

export const getDocByUid = async (
  doctype: string,
  uid: string,
  client: prismic.Client
): Promise<PrismicDocument | null> => {
  if (!uid) return null;

  let doc = null;
  try {
    doc = await client.getByUID(doctype, uid);
  } catch (e) {
    Sentry.captureException(e);
    console.warn('Error getting doc by uid', uid, e);
    return null;
  }

  return doc;
};

export const getDocByType = async (doctype: string, client: prismic.Client) => {
  let doc = null;
  try {
    doc = await client.getByType(doctype);
  } catch (e) {
    console.log('Error getting doc by type', doctype, e);
  }
  return doc;
};
