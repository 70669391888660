import getProductSearchResults from './get-product-search-results';

const getPrefillProductsFromShopifyBySku = async (skuList, clientSettings) => {
  const skuArray = skuList.split(',');

  const skuQuery = skuArray.map((sku) => `${sku}`).join(' OR ');

  const searchResults = await getProductSearchResults(clientSettings, skuQuery, [], 100);

  if (!searchResults || !searchResults.products) {
    return [];
  }

  // Filter the products by IDs
  const matchingProducts = searchResults.products.edges.filter(
    (productEdge) => skuArray.includes(productEdge.node.variants.edges[0].node.sku) // TODO
  );

  return matchingProducts.map((productEdge) => ({
    id: productEdge.node.id,
    handle: productEdge.node.handle,
    title: productEdge.node.title,
    variant: productEdge.node.variants.edges[0].node,
    image: productEdge.node.images.edges[0].node.transformedSrc,
  }));
};

export default getPrefillProductsFromShopifyBySku;
