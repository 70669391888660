import React, { useEffect, useRef, useContext } from 'react';
import Image from 'next/image';
import { useUI } from '@components/ui/context';
import getProductSearchResults from '../../lib/shopify/methods/get-product-search-results';
import defaultProductSearchFilter from '@lib/shopify/filters/default-product-search-filter';
import { getShopifyClientSettings } from '@lib/shopify/get-client-settings';
import getArticleSearchResults from '@lib/shopify/methods/get-article-search-results';
import { useRouter } from 'next/router';
import { EventContext } from 'providers/EventProvider';

const ShopifySearchInputField = ({
  setQuery,
  query,
  setSuggestedKeywords,
  setSuggestedCollections,
  setIsGettingSearchResults,
}) => {
  const { setShopifySearchResults, isSearchFormFocused, closeSearchPanel } = useUI();
  const inputRef = useRef(null);
  const router = useRouter();
  const clientSettings = getShopifyClientSettings('AU');
  const { gtm } = useContext(EventContext);

  const getSearchResultsByQuery = async () => {
    setIsGettingSearchResults(true);
    if (query !== '' && query.length >= 3) {
      let productResults: any = await getProductSearchResults(
        clientSettings,
        query,
        defaultProductSearchFilter,
        4
      );

      let articleResults: any = await getArticleSearchResults(clientSettings, query, [], 4);

      setShopifySearchResults({
        products: productResults.products.edges,
        articles: articleResults.relatedArticles.edges,
      });
      setIsGettingSearchResults(false);
    }
    if (query === '') {
      setShopifySearchResults({
        products: [],
        articles: [],
      });
    }
  };

  const fetchSuggestions = async (searchTerm: string) => {
    if (!searchTerm) return;

    try {
      if (searchTerm.length >= 3) {
        const res = await fetch('/api/shopify/searchSuggestions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: searchTerm }),
        });

        const { suggestedKeywords, suggestedCollections } = await res.json();
        setSuggestedKeywords(
          suggestedKeywords.filter((item) => item.toLowerCase() !== searchTerm.toLowerCase())
        );
        setSuggestedCollections(suggestedCollections.slice(0, 6));
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && window.innerWidth >= 767) {
      e.preventDefault();

      gtm.tagSearchResult({ label: query });

      router.push(`/search?searchTerm=${query}&searchType=Product`);

      closeSearchPanel();
      setTimeout(() => {
        setQuery('');
      }, 800);
    }
  };

  useEffect(() => {
    getSearchResultsByQuery();
    fetchSuggestions(query);

    if (query === '') {
      setSuggestedKeywords([]);
      setSuggestedCollections([]);
    }
  }, [query]);

  const onFocus = () => {
    clearSearchHandler();

    gtm.tagClickSearchBox();

    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter' || event.keyCode === 13) {
        event.preventDefault();
        event.target.blur();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  };

  const clearSearchHandler = () => {
    setQuery('');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchFormFocused]);

  return (
    <div>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onFocus={onFocus}
        />
        <div className="absolute top-7 left-2">
          <Image src="/icons/resultsPanelSearchIcon.svg" width={18} height={18} alt="Search" />
        </div>
        {query && query.length !== 0 && (
          <button
            className="absolute top-6 right-6 text-ui-dark-grey underline"
            onClick={() => {
              setQuery('');
            }}
          >
            Clear
          </button>
        )}
      </div>
    </div>
  );
};

export default ShopifySearchInputField;
